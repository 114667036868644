import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import * as Yup from 'yup'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import Router from 'next/router'
import CredentialsForm from '../CredentialsForm'
import { loginMutation } from '../../graphql/account'

const LoginForm = (props) => {
  const [login] = useMutation(loginMutation, {
    onCompleted: () => {
      // eslint-disable-next-line react/destructuring-assignment
      props.reRoute()
    }
  })
  const inputFields = [
    { label: 'Email', name: 'email', type: 'email' },
    { label: 'Password', name: 'password', type: 'password' },
    { label: 'Remember Me', name: 'rememberMe', type: 'checkbox' }
  ]

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required')
  })

  const initialValues = { email: '', password: '', rememberMe: false }

  const handleSubmit = async (values, { setStatus }) => {
    try {
      await login({ variables: values })
    } catch (e) {
      if (e.message) setStatus(e.message)
    }
  }
  return (
    <CredentialsForm
      inputFields={inputFields}
      validationSchema={validationSchema}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      submitText='Login'
      {...props}
    >
      <Box display='flex' my={2} justifyContent='space-between' flexWrap='wrap'>
        <Button size='small' onClick={() => Router.push('/forgotPassword')}>
          Forgot Your Password?
        </Button>
      </Box>
    </CredentialsForm>
  )
}

export default LoginForm
