import { styled } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

export const AuthViewWrapper = styled(Paper)(() => ({
  display: 'flex'
}))

export const BackgroundContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'stretch',
  alignContent: 'stretch',
  minHeight: '100vh',
  justifyContent: 'stretch'
}))

export const Background = styled('div')(({ showBackground }) => ({
  display: 'flex',
  backgroundImage: showBackground
    ? "linear-gradient(180deg, rgba(42, 118, 226, 0), hsla(0, 0%, 83.1%, 0.15) 80%, hsla(0, 0%, 100%, 0.5)), url('/static/images/MockupEngine-Robot.png'), url('/static/images/DropMock-AI-login-background-v1-min.jpg')"
    : 'none',
  backgroundPosition: '100% 50%, 110% 50%, center',
  backgroundSize: 'cover, contain, cover',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  backgroundAttachment: 'scroll, scroll, scroll',
  flex: '1 1',
  justifyContent: 'center',
  alignItems: 'center'
}))
