import React from 'react'

// import { Link as NextLink } from 'next/link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
// import Link from '@material-ui/core/Link'
// import { getSocialPlatforms } from '../../graphql/account'
// import SocialPlatformsForm from '../SocialPlatformsForm'
// import LoadingAnimation from '../LoadingAnimation'

const AuthView = ({
  // formType,
  // callingPage,
  children
}) => {
  // const { data, loading } = useQuery(getSocialPlatforms)

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      py={6}
      px={10}
      flex={1}
    >
      <Box mb={2}>
        {/* <Link href='/home' component={NextLink}> */}
        <img
          src='/static/images/Dropmock-AI-logo.svg'
          height='100px'
          alt='Dropmock'
        />
        {/* </Link> */}
      </Box>
      {children}
      {/* {formType !== 'forgot' && (
        <Box>
          {loading ? (
            <LoadingAnimation />
          ) : (
            <SocialPlatformsForm
              text={formType}
              platforms={data.socialPlatforms || []}
              callingPage={callingPage}
            />
          )}
        </Box>
      )} */}
      {/* <Box>
        <Typography avriant='body1' component='span'>
          {formType === 'login' ? "Don't" : 'Already'}
          {' have an account?'}
        </Typography>
        {formType === 'login' ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link component={Button} onClick={() => Router.push('/signup')}>
            Sign Up here
          </Link>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link component={Button} onClick={() => Router.push('/login')}>
            Login here
          </Link>
        )}
      </Box> */}
      <Box>
        <Typography variant='caption'>
          ©2021 DropMock. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  )
}

export default AuthView
