import React from 'react'

import Router from 'next/router'

import LoginForm from '../LoginForm'
import AuthView from '../AuthView'

const LoginView = ({ reRouteOverride, callingPage }) => {
  return (
    <AuthView callingPage={callingPage} formType='login'>
      <LoginForm
        submitText='Login'
        reRoute={reRouteOverride || (() => Router.push('/dashboard'))}
      />
    </AuthView>
  )
}

export default LoginView
