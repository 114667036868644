import React from 'react'
import Head from 'next/head'
import LoginView from '../../components/LoginView'
import { getSocialPlatforms } from '../../graphql/account'
import getQuery from '../../lib/getQuery'
import AuthWrapper from '../../components/AuthWrapper'

const Login = ({
  setLightTheme,
  setShowFooter,
  setShowHeader,
  setEditorHeader
}) => {
  setLightTheme(false)
  setShowFooter(false)
  setShowHeader(false)
  setEditorHeader(false)
  return (
    <>
      <Head>
        <title>DropMock | Login</title>
      </Head>
      <AuthWrapper>
        <LoginView />
      </AuthWrapper>
    </>
  )
}

Login.getInitialProps = async ({ apolloClient }) => {
  const { socialPlatforms } = await getQuery(apolloClient, {
    query: getSocialPlatforms
  })
  return { socialPlatforms }
}

export default Login
