import React from 'react'
import PropTypes from 'prop-types'
import {
  AuthViewWrapper,
  BackgroundContainer,
  Background
} from './AuthWrapper.styles'

const AuthWrapper = ({ withBackground, children }) => (
  <BackgroundContainer>
    <Background showBackground={withBackground}>
      <AuthViewWrapper elevation={withBackground ? 1 : 0}>
        {children}
      </AuthViewWrapper>
    </Background>
  </BackgroundContainer>
)

AuthWrapper.propTypes = {
  withBackground: PropTypes.bool,
  children: PropTypes.node.isRequired
}

AuthWrapper.defaultProps = {
  withBackground: true
}

export default AuthWrapper
